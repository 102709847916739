import { useNavigate } from "react-router-dom";
import { Card, message, Statistic } from "antd";
import { useQuery } from "react-query";
import { IEditRequest, IFeaturedPosts, IInnovationType } from "../types";
import { api } from "../api/api";
import { useState } from "react";
import { VscLoading } from "react-icons/vsc";
import { MdCompost } from "react-icons/md";
import { PiClockCountdownFill } from "react-icons/pi";
import { BsFillPostcardFill } from "react-icons/bs";
import { TbEditCircle } from "react-icons/tb";

const Default = () => {
  const [allInnovations, setAllInnovations] = useState<IInnovationType[]>([]);
  const [pendingInnovations, setPendingInnovations] = useState<
    IInnovationType[]
  >([]);
  const [featuredPosts, setFeaturedPosts] = useState<IFeaturedPosts[]>([]);

  const navigate = useNavigate();
  const getInnovations = async () => {
    const { data } = await api.get<IInnovationType[]>("/innovation");
    return data;
  };

  const [request, setRequest] = useState<IEditRequest[]>([]);

  const getAllRequest = async () => {
    const { data } = await api.get<IEditRequest[]>("/edit-request");
    return data;
  };

  const { isLoading: isRequesting } = useQuery(
    "get-all-edit-request",
    () => getAllRequest(),
    {
      onSuccess: (data) => {
        setRequest(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { isLoading } = useQuery(
    ["get-all-innovation"],
    () => getInnovations(),
    {
      keepPreviousData: true,
      onError: () => {
        message.error("Could not get innovations at this moment");
      },
      onSuccess: (data) => {
        const pending = data.filter(
          (innovation) => innovation.status === "pending"
        );

        setAllInnovations(data);
        setPendingInnovations(pending);
      },
    }
  );
  const getFeaturedPosts = async () => {
    const { data } = await api.get<IFeaturedPosts[]>("/featured");
    return data;
  };

  const { isLoading: isFeaturedLoading } = useQuery(
    "get-all-featured-posts-home",
    getFeaturedPosts,
    {
      onSuccess: (data) => {
        setFeaturedPosts(data);
      },
      onError: () => {
        message.error("Network error!");
      },
    }
  );

  return (
    <div>
      <Card bordered={false} className="w-full">
        <div className="text-xl md:text-3xl font-bold mb-3 md:mb-5">
          Dashboard
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
          <div
            className="h-[130px] flex items-center p-5 w-full bg-white border border-primary rounded-2xl shadow-sm hover:shadow-md cursor-pointer"
            onClick={() => navigate("innovations")}
          >
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col gap-3">
                <h3 className="font-[350]">Total Innovations</h3>

                {isLoading ? (
                  <div>
                    <VscLoading className="animate-spin" />
                  </div>
                ) : (
                  <h2 className="text-2xl md:text-4xl lg:text-4xl font-semibold">
                    {allInnovations.length}
                  </h2>
                )}
              </div>
              <div>
                <MdCompost size={30} className="text-primary" />
              </div>
            </div>
          </div>
          <div
            className="h-[130px] flex items-center p-5 w-full bg-white border border-primary rounded-2xl shadow-sm hover:shadow-md cursor-pointer"
            onClick={() => navigate("innovations")}
          >
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col gap-3">
                <h3 className="font-[350]">Pending Approvals</h3>

                {isLoading ? (
                  <div>
                    <VscLoading className="animate-spin" />
                  </div>
                ) : (
                  <h2 className="text-2xl md:text-4xl lg:text-4xl font-semibold">
                    {pendingInnovations.length}
                  </h2>
                )}
              </div>
              <div>
                <PiClockCountdownFill size={30} className="text-yellow-500" />
              </div>
            </div>
          </div>
          <div
            className="h-[130px] flex items-center p-5 w-full bg-white border border-primary shadow-sm hover:shadow-md rounded-2xl cursor-pointer"
            onClick={() => navigate("posts")}
          >
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col gap-3">
                <h3 className="font-[350]">Featured Posts</h3>

                {isFeaturedLoading ? (
                  <div>
                    <VscLoading className="animate-spin" />
                  </div>
                ) : (
                  <h2 className="text-2xl md:text-4xl lg:text-4xl font-semibold">
                    {featuredPosts.length}
                  </h2>
                )}
              </div>
              <div>
                <BsFillPostcardFill size={30} className="text-gray-600" />
              </div>
            </div>
          </div>
          <div
            className="h-[130px] flex items-center p-5 w-full bg-white border border-primary rounded-2xl cursor-pointer shadow-sm hover:shadow-md"
            onClick={() => navigate("edit-access")}
          >
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col gap-3">
                <h3 className="font-[350]">Edit Requests</h3>

                {isFeaturedLoading ? (
                  <div>
                    <VscLoading className="animate-spin" />
                  </div>
                ) : (
                  <h2 className="text-2xl md:text-4xl lg:text-4xl font-semibold">
                    {request.length}
                  </h2>
                )}
              </div>
              <div>
                <TbEditCircle size={30} className="text-red-600" />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Default;
