//eslint-disable-next-line
import { Image } from "antd";
import ReactPlayer from "react-player";

interface IMedia {
  name: string;
  url: string;
  type?: string;
  size?: number;
}

interface RenderMediaProps {
  media: IMedia;
  className?: string;
}

export const RenderMedia = ({ media, className }: RenderMediaProps) => {
  const extension = media?.url.split(".").pop()?.toLowerCase() || "";

  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const videoExtensions = ["mp4", "webm", "ogg"];

  if (imageExtensions.includes(extension)) {
    return <Image src={media.url} className={className} />;
  }

  if (videoExtensions.includes(extension)) {
    const videoThumbnail = `${media.url}#t=0.5`;

    return (
      <div className={`relative ${className}`}>
        <ReactPlayer
          url={media.url}
          light={videoThumbnail}
          controls={true}
          width="100%"
          height="100%"
          playing={true}
        />
      </div>
    );
  }

  return (
    <div className="text-center text-muted-foreground h-[100px] flex justify-center items-center">
      --- No data ----
    </div>
  );
};

//eslint-disable-next-line
// import { Image } from "antd";
// import ReactPlayer from "react-player";

// interface IMedia {
//   name: string;
//   url: string;
//   type?: string;
//   size?: number;
// }

// interface RenderMediaProps {
//   media: IMedia;
//   className?: string;
// }

// export const RenderMedia = ({ media, className }: RenderMediaProps) => {
//   const extension = media?.url.split(".").pop()?.toLowerCase() || "";

//   const imageExtensions = ["jpg", "jpeg", "png", "gif"];
//   const videoExtensions = ["mp4", "webm", "ogg"];

//   const isFacebook = media.url.includes("facebook.com");
//   const isInstagram = media.url.includes("instagram.com");

//   console.log({ isFacebook, isInstagram });

//   if (imageExtensions.includes(extension)) {
//     return <Image src={media.url} className={className} />;
//   }

//   if (videoExtensions.includes(extension)) {
//     const videoThumbnail = `${media.url}#t=0.5`;

//     return (
//       <div className={`relative ${className}`}>
//         <ReactPlayer
//           url={media.url}
//           light={videoThumbnail}
//           controls={true}
//           width="100%"
//           height="100%"
//         />
//       </div>
//     );
//   }

//   if (isFacebook || isInstagram) {
//     return (
//       <iframe
//         src={media.url}
//         className={`w-full h-[500px] border-0 ${className}`}
//         allowFullScreen
//         title={media.url}
//       />
//     );
//   }

//   return (
//     <div className="text-center text-muted-foreground h-[100px] flex justify-center items-center">
//       --- No data ----
//     </div>
//   );
// };
