export const AUTH_TOKEN_KEY = "sessionid";

// https://goldfish-app-dkqw4.ondigitalocean.app

export const BACKEND_API = "https://goldfish-app-dkqw4.ondigitalocean.app";

export const VALUE_CHAIN_OPTIONS = [
  { value: "Input Supply", label: "Input Supply" },
  { value: "Production", label: "Production" },
  { value: "Harvesting", label: "Harvesting" },
  { value: "Processing", label: "Processing" },
  { value: "Logistics", label: "Logistics" },
  { value: "Export", label: "Export" },
];
